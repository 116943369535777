import { GameField } from "../../core/Engine/Engine";
import Entity from "../../entities/entity/entity";
import Monster from "../../entities/monster/monster";
import Player from "../../entities/player/player";
import Projectile from "../../entities/projectile/projectile";

export class Renderer {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  gameField: GameField = { width: 0, height: 0 };

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d")!;
    this.updateGameFieldDimensions();
  }

  updateGameFieldDimensions() {
    const menuBarWidth = this.canvas.width * 0.2;
    this.gameField = {
      width: this.canvas.width - menuBarWidth,
      height: this.canvas.height,
    };
  }

  render(entities: Entity[], player: Player) {
    if (!this.ctx) return;

    this.renderGameField();
    this.renderEntities(entities);
    this.renderMenuBar(player);
  }

  renderGameField() {
    const menuBarWidth = this.canvas.width * 0.2;
    const gameFieldWidth = this.canvas.width - menuBarWidth;
    const gameFieldHeight = this.canvas.height;

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.fillStyle = "black";
    this.ctx.fillRect(0, 0, gameFieldWidth, gameFieldHeight);
  }

  renderEntities(entities: Entity[]) {
    const menuBarWidth = this.canvas.width * 0.2;
    const gameFieldWidth = this.canvas.width - menuBarWidth;
    const gameFieldHeight = this.canvas.height;

    const scaleX = gameFieldWidth / this.gameField.width;
    const scaleY = gameFieldHeight / this.gameField.height;

    for (const entity of entities) {
      const scaledX = entity.x * scaleX;
      const scaledY = entity.y * scaleY;
      const scaledWidth = entity.width * scaleX;
      const scaledHeight = entity.height * scaleY;

      if (scaledX < gameFieldWidth) {
        if (entity instanceof Player || entity instanceof Monster) {
          this.ctx.beginPath();
          this.ctx.arc(
            scaledX + scaledWidth / 2,
            scaledY + scaledHeight / 2,
            scaledWidth / 2,
            0,
            2 * Math.PI
          );
          this.ctx.fillStyle = entity.color;
          this.ctx.fill();
        }
        // RENDER PROJECTILES
        if (entity instanceof Projectile) {
          this.ctx.fillStyle = entity.color;
          this.ctx.fillRect(scaledX, scaledY, scaledWidth, scaledHeight);
        }
      }
    }
  }

  renderMenuBar(player: Player) {
    const menuBarWidth = this.canvas.width * 0.2;
    const menuBarHeight = this.canvas.height;
    const menuBarX = this.canvas.width - menuBarWidth;

    this.ctx.fillStyle = "darkgrey";
    this.ctx.fillRect(menuBarX, 0, menuBarWidth, menuBarHeight);

    // Render health bar
    const healthBarWidth = menuBarWidth * 0.8;
    const healthBarHeight = 20;
    const healthBarX = menuBarX + (menuBarWidth - healthBarWidth) / 2;
    const healthBarY = 20;

    this.ctx.fillStyle = "red";
    this.ctx.fillRect(healthBarX, healthBarY, healthBarWidth, healthBarHeight);

    const currentHealthWidth = (player.health / 100) * healthBarWidth;
    this.ctx.fillStyle = "green";
    this.ctx.fillRect(
      healthBarX,
      healthBarY,
      currentHealthWidth,
      healthBarHeight
    );

    // Render health text
    this.ctx.fillStyle = "black";
    this.ctx.font = "20px Arial";
    this.ctx.fillText(`HP: ${player.health}`, healthBarX, healthBarY + 40);
  }
}
