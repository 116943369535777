import Entity from "../entity/entity";

class Player extends Entity {
  health: number;

  constructor(
    x: number,
    y: number,
    width: number,
    height: number,
    color: string,
    speed: number,
    health: number
  ) {
    super(x, y, width, height, color, speed);
    this.health = health;
  }

  update(
    gameTick: number,
    canvas: HTMLCanvasElement,
    _?: Player,
    keys?: { [key: string]: boolean }
  ) {
    if (!keys || !canvas) return;

    const velocityX = (keys["d"] ? 1 : 0) - (keys["a"] ? 1 : 0);
    const velocityY = (keys["s"] ? 1 : 0) - (keys["w"] ? 1 : 0);

    this.x += velocityX * this.speed * gameTick;
    this.y += velocityY * this.speed * gameTick;
  }

  handlePlayerHit() {
    this.health -= 5;
  }
}

export default Player;
